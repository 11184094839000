<template>

    <div class="max-w-full w-fit flex flex-col gap-6 items-center bg-main-100/70 rounded-lg px-8 py-4 text-main-5 shrink-0 shadow-material" v-if="this.userSubscription.active">

        <div class="flex flex-col gap-3 items-center">

            <i class="icon-diamond text-[48px]"/>

            <p class="text-center text-base font-bold">{{ this.$st('payments.modals.status-card.your-plan', { name: this.userSubscription.name }) }}</p>

            <div class="relative h-1 max-w-full w-[160px] rounded-lg bg-second-100/40">

                <div
                    class="absolute left-0 h-full w-0 bg-cta-100 rounded-lg transition-[width] duration-500 after:block after:absolute after:right-0 after:h-full"
                    :class="[{'bg-pro_dark after:aspect-square after:shadow-[0_0_14px_3px_theme(colors.cta.100)]': this.userSubscription.slansPercentage > 5}, {'!bg-error': this.userSubscription.slansPercentage >= 100}]"
                    :style="`max-width: 100%; width: ${this.userSubscription.slansPercentage}%`"/>

            </div>

            <p> {{ $st('payments.used-slans', { value: `${this.userSubscription.usedSlans}/${this.userSubscription.usageLimit}` }) }} </p>

        </div>

        <Button size="sm" variant="pro" :text="this.$st('payments.improve-plan')" @click="this.$router.push({ name: 'Payments' })"/>

    </div>

</template>

<script>
import store from '@/store'
import Button from '@/slango-multiverse/components/inputs/Button.vue'

export default {

    components: { Button },

    data: function() {

        return {}
    },

    computed: {

		userSubscription() { return store.getters.currentUserSubscription }
	},
}
</script>